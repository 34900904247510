var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),(_vm.$route.name === 'TicketingRequestList')?_c('v-row',[_c('v-col',{attrs:{"xl":"6","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('v-card-title',{staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(_vm.$t('TicketingPopup.loadedAppUser'))+" ")]),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.ticketing.downloadS3HeadersAppUser,"items":_vm.ticketing.documentS3ItemsAppUser,"footer-props":{
          itemsPerPageAllText: _vm.$t('Table.all'),
          itemsPerPageText: _vm.$t('Table.rowsPerPage'),
          pageText: ("{0}-{1} " + (_vm.$t('Table.of')) + " {2}")
        },"no-data-text":_vm.$t('Table.noFile')},scopedSlots:_vm._u([_vm._l((_vm.ticketing.downloadS3HeadersAppUser),function(h){return {key:("header." + (h.value)),fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}}}),{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"pt-1",attrs:{"size":22},on:{"click":function($event){return _vm.dispatchDownloadItem(item)}}},[_vm._v(" mdi-download ")]),_c('delete',{staticClass:"mb-1",attrs:{"id":item.name},on:{"deleted":function($event){return _vm.deleteS3Item(item)}}})]}}],null,true)})],1),_c('v-col',{attrs:{"xl":"6","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('v-card-title',{staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(_vm.$t('TicketingPopup.loadedRadarmeteo'))+" ")]),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.ticketing.downloadS3HeadersRadarMeteo,"items":_vm.showItemsRadarmeteo,"footer-props":{
          itemsPerPageAllText: _vm.$t('Table.all'),
          itemsPerPageText: _vm.$t('Table.rowsPerPage'),
          pageText: ("{0}-{1} " + (_vm.$t('Table.of')) + " {2}")
        },"no-data-text":_vm.$t('Table.noFile')},scopedSlots:_vm._u([_vm._l((_vm.ticketing.downloadS3HeadersRadarMeteo),function(h){return {key:("header." + (h.value)),fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}}}),{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"pt-1",attrs:{"size":22},on:{"click":function($event){return _vm.dispatchDownloadItem(item)}}},[_vm._v(" mdi-download ")]),(!_vm.isDetail)?_c('div',[_c('delete',{staticClass:"mb-1",attrs:{"id":item.name},on:{"deleted":function($event){return _vm.deleteS3Item(item)}}})],1):_vm._e()]}}],null,true)})],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',{staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(_vm.$t('TicketingPopup.loadedAppUser'))+" ")]),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.ticketing.downloadS3HeadersAppUser,"items":_vm.ticketing.documentS3ItemsAppUser,"footer-props":{
          itemsPerPageAllText: _vm.$t('Table.all'),
          itemsPerPageText: _vm.$t('Table.rowsPerPage'),
          pageText: ("{0}-{1} " + (_vm.$t('Table.of')) + " {2}")
        },"no-data-text":_vm.$t('Table.noFile')},scopedSlots:_vm._u([_vm._l((_vm.ticketing.downloadS3HeadersAppUser),function(h){return {key:("header." + (h.value)),fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}}}),{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"pt-1",attrs:{"size":22},on:{"click":function($event){return _vm.dispatchDownloadItem(item)}}},[_vm._v(" mdi-download ")]),_c('delete',{staticClass:"mb-1",attrs:{"id":item.name},on:{"deleted":function($event){return _vm.deleteS3Item(item)}}})]}}],null,true)})],1)],1),(_vm.showExtraInformations)?_c('v-card-text',{staticClass:"d-flex justify-space-between mt-5"},[_c('v-divider'),_c('p',{staticClass:"mx-8 mt-n4 text-h6"},[_vm._v(" "+_vm._s(_vm.$t('TicketingPopup.requestMoreInformation'))+" ")]),_c('v-divider')],1):_vm._e(),((_vm.extraInformation && _vm.canInsertFile) || _vm.$route.name === 'TicketingRequest')?_c('v-file-input',{staticClass:"mt-4",attrs:{"accept":".pdf, .txt, .csv, .xlsx, .zip, .rar","multiple":"","placeholder":_vm.$t('Ticketing.input'),"prepend-inner-icon":"mdi-file-document-outline","outlined":"","hint":_vm.$t('CustomerLayer.check'),"truncate-length":"50","disabled":_vm.disableForm || !_vm.canInsertFile},on:{"change":_vm.confirmFile},model:{value:(_vm.manualFile),callback:function ($$v) {_vm.manualFile=$$v},expression:"manualFile"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }